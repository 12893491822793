.modalTitle {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.explanWrap {
  font-size: 14px;
  color: #333333;

  .title {
    margin-bottom: 2px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    margin-bottom: 6px;
    text-indent: 1em;
  }
}