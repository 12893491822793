.count {
  font-size: 30px;
  color: rgba(0, 0, 0, .85);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;

  &.redColor {
    color: rgb(237, 63, 20);
  }

  &.blueColor {
    color: rgb(45, 140, 240);
  }

  &.greenColor {
    color: rgb(25, 190, 107);
  }

  &.voilColor {
    color: rgb(154, 102, 228);
  }
}

.itemCount {
  font-size: 22px;
  color: rgba(0, 0, 0, .85);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}