
.tableView {
  width: 100%;
  overflow: hidden;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.tableRow {
  border-bottom: 1px solid #e8e8e8;

  &:last-child {
    border-bottom: none;
  }

  .rowLabel {
    padding: 16px 24px;
    border-right: 1px solid #e8e8e8;
    background-color: #fafafa;
    width: 150px;
  }

  .rowCon {
    padding: 16px 24px;
  }
}