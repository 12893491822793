.companyLand {
  padding-bottom: 75px;
}

.footerBar {
  padding: 0 15px;
  position: fixed;
  left: 200px;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, .08), 0 -9px 28px 0 rgba(0, 0, 0, .05), 0 -12px 48px 16px rgba(0, 0, 0, .03);
}