.label {
  position: relative;
  font-size: 14px;
  color: #333333;

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}