.inline {
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0px;
}

.priceCol {
  width: 110px;
}

.stockCol {
  width: 120px;
  color: #FF7900;

  .label {
    color: #FF7900;
  }
}