
.inline {
  margin-right: 24px;
  display: inline-block;
  vertical-align: top;
}

.formExtra {
  position: relative;

  &:before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: 'SimSun', 'sans-serif';
    line-height: 1;
  }
}