.uploadFormitemRow {
  :global {
    .ant-form-item-control {
      line-height: normal;
    }

    .ant-upload.ant-upload-select-picture-card {
      margin-bottom: 4px;
    }
  }
}

.required {
  position: relative;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.formitemLabel {
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

.dynamicDeleteButton {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  margin-left: 10px;
  color: #1890FF;
  transition: all 0.3s;
}