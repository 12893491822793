.show {
  display: flex;
  float: right;
  align-items: center;
  text-align: center;
  margin-bottom: 5px;

  div {
    margin-right: 10px;
  }
}

.wh {
  width: 30px;
  height: 30px;
}

.bgIn {
  background-color: #FF9C00;
}

.bgOut {
  background-color: #FF5D2A;
}

.LEVEL_2 {
  color: #FF9C00;
}

.LEVEL_1 {
  color: #FF5D2A;
}