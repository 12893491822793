:global {
  .ant-dropdown-menu-bar {
    display: none;
  }
}

.headerBar {
  position: relative;
  padding: 0px 15px;
  height: 44px;
  line-height: 44px;
  width: 100%;
  background-color: #ffffff;

  .customBread {
    display: inline-block;
    height: 100%;

    :global {
      .ant-breadcrumb {
        line-height: inherit;
      }
    }
  }

  .customCon {
    float: right;
    padding-right: 20px;
    height: auto;
    height: 44px;
    line-height: 44px;
  }

  .inline {
    padding: 0px 10px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
}

.noticeDropWrap {
  padding: 6px 15px 12px 15px;
  width: 340px;

  .noticeHeader {
    display: flex;

    .dropTitle {
      flex: 1;
      margin-right: 10px;
    }

    .dropAction {
      color: #1890ff;
      cursor: pointer;
    }
  }

  .noticeBody {
    margin-top: 10px;
  }

  .noticeItem {
    margin-bottom: 5px;
    display: block;
    line-height: normal;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
    transition: all 0.3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      color: #1890FF;
    }
  }
}

.loadingRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}