.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.trailFloor {
  .upTitle {
    position: relative;
    height: 30px;
    line-height: 30px;

    .titleItem {
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
      color: #333333;

      img {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    .tipsTop {
      position: absolute;
      font-size: 12px;
      color: #333333;
      right: 10px;
    }
  }

  .searchBg {
    float: left;
    background: url('../../../assets/images/bar.png') center center;
    width: 100%;
    height: 4px;
  }

  .storeContent {
    padding-bottom: 20px;
    position: relative;
  }

  .scShow {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;
    margin-left: 16px;

    .trailItem {
      font-size: 12px;
      color: #333333;
      height: 50px;
      line-height: 50px;

      &:first-child {
        color: #ff871c;
      }

      span {
        margin: 0px;
        display: inline-block;
        vertical-align: top;
        word-wrap: break-word;
        border: none;
      }

      .sub1 {
        padding-top: 15px;
        width: 70px;
        line-height: 15px;
        box-sizing: border-box;
        word-wrap: break-word;
      }

      .sub2 {
        box-sizing: border-box;
        width: 40px;
        height: 50px;
        background-image: url('../../../assets/images/bgwl.png');
        background-repeat: no-repeat;
        background-position: -11px -43px;

        &.subHead {
          background-image: url('../../../assets/images/bgwl.png');
          background-repeat: no-repeat;
          background-position: -11px 13px;
        }

        &.subFooter {
          background-image: url('../../../assets/images/bgwl.png');
          background-repeat: no-repeat;
          background-position: -11px -350px;
        }
      }

      .sub3 {
        padding-top: 15px;
        width: 560px;
        line-height: 18px;
      }
    }
  }
}