.errorRow {
  display: flex;
}

.rowContent {
  padding: 5px;
  text-align: center;
  margin-right: 12px;
  width: 1510px;
}

.hintText {
  margin-bottom: 4px;
  font-size: 12px;
  color: #333333;
}

.rowAction {
  display: flex;
  align-items: center;
}

.errorDetail {
  color: #F03A58;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn {
  width: 70px;
  font-size: 12px;
  border-radius: 2px;
}

.correctBtn {
  background-color: #F5222D;

  &:hover,
  &:focus {
    background-color: #F5222D;
  }
}