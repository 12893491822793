.goodsAdress {
  height: 46px;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.costTitle {
  margin-right: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.costM {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #F5222D;
}

.footBtn {
  margin-top: 20px;
  text-align: right;
}