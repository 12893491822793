.asideWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.asideLogo {
  height: 44px;
}

.asideContent {
  flex: 1 1 0%;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 100%, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, .15);
    border-radius: 3px;
  }
}

.asideLinks {
  height: 44px;
}