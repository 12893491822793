.loginWrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/login-bg02.jpg') no-repeat;
  background-size: cover;
}

.loginContent {
  padding-top: 40px;
  padding-bottom: 20px;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  transform: translate3d(0, -50%, 0);
  background-color: rgba(0, 0, 0, 0.5);
}

.title {
  color: #ffffff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0px;
}

.desc {
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}

.loginFormWrap {
  margin: 0px auto;
  width: 380px;
}

.loginButton {
  width: 100%;
  height: 44px;
}