.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.widgetItem {
  position: relative;
  margin: 2px 2px 12px 2px;
  border: 1px dashed hsla(0, 0%, 66.7%, 0.7);
  background-color: rgba(236, 245, 255, 0.3);

  &.active {
    border: 1px solid #1890FF;
    outline: 2px solid #1890FF;
  }
}

.widgetContent {
  margin: 0px 60px 0px 40px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color: #1890FF;

  &.errorBorder {
    border: 1px solid #F5222D;
  }
}

.widgetDrag {
  position: absolute;
  left: -2px;
  top: -2px;
  background-color: #1890FF;
  color: #ffffff;
  cursor: move;
  z-index: 9;

  .iconDrag {
    margin: 4px;
    font-size: 14px;
  }
}

.widgetAction {
  position: absolute;
  right: 0px;
  bottom: -2px;
  background-color: #1890FF;
  color: #ffffff;
  z-index: 9;

  .iconAction {
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
  }
}