.searchCol {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.searchOperation {
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
