.searchInput {
  :global {
    .ant-input {
      border: 1px solid #A2ADC3;
      border-radius: 0px;
    }
  }
}

.searchResult {
  border-left: 1px solid #A2ADC3;
  border-right: 1px solid #A2ADC3;
  border-bottom: 1px solid #A2ADC3;

  .searchItem {
    display: flex;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    color: #333;
    cursor: pointer;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  .product {
    flex: 1;
    margin-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .btnWrap {
    .confirmBtn {
      display: inline-block;
      padding: 2px 8px;
      color: #ffffff;
      font-size: 12px;
      border-radius: 2px;
      background-color: #FF4444;
    }
  }
}

.popoverTitleLand {
  display: flex;

  .popoverTitle {
    flex: 1;
  }

  .closeWrap {
    width: 30px;
    text-align: right;
    cursor: pointer;
  }
}

.errorBg {
  background-color: #fff2f2;
}