.thumbWrap {
  display: block;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .prewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.formLabel {
  position: relative;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}

.formLabel:after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.formExtra {
  position: relative;

  &:before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: 'SimSun', 'sans-serif';
    line-height: 1;
  }
}

.searchLand {
  position: relative;

  .uncompanyLand {
    padding-bottom: 15px;
    position: absolute;
    top: 36px;
    left: 80px;
    right: 0px;
    z-index: 90;
    max-height: 210px;
    overflow-y: auto;
    background-color: #ffffff;
    border-left: 1px solid #1890ff;
    border-right: 1px solid #1890ff;
    border-bottom: 1px solid #1890ff;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    li {
      padding: 5px 15px;
      cursor: pointer;
      line-height: normal;

      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}

.baseLand {
  width: 500px;
}

.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}