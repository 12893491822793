.main {
  margin-left: 30px;
  margin-top: 30px;

  h1 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
}

.useBalance {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 40px;
  font-size: 14px;
  color: #333333;
  border: 1px solid #BABABA;
  border-radius: 4px;
  cursor: pointer;

  &.selected {
    border: 2px solid #F5222D;
  }

  i {
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    right: -1px;
    bottom: -1px;
    background-image: url('~@/assets/images/checked02.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
}

.info {
  display: flex;

}

.passWrap {
  margin-left: 10px;
  width: 250px;
  height: 40px;

  .passInput {
    height: 40px;
  }
}

.landTips {
  margin-top: 10px;
  font-size: 14px;
  color: #333333;

  span {
    font-size: 14px;
    color: #F5222D;
  }
}