.footBtn {
  margin-top: 24px;
  text-align: right;
}

.title {
  padding-bottom: 16px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}