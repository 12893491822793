.errorTitle {
  display: block;
  margin-top: 60px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #333333;
}

.errorBox {
  margin-top: 60px;
  margin-left: 36px;
  padding-bottom: 100px;

  .showError {
    float: left;
    padding-left: 55px;
    margin-left: 5px;
    width: 48%;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }
}

.errorIcon1 {
  position: relative;
  border-right: 1px solid #e5e5e5;
  padding-right: 10px;
  margin-right: 10px;

  &::before {
    content: "";
    position: absolute;
    width: 34px;
    height: 34px;
    left: 10px;
    top: 6px;
    background: url('../../assets/images/error-icon1.png') no-repeat center center;
  }
}

.errorIcon2 {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 34px;
    height: 34px;
    left: 10px;
    top: 6px;
    background: url('../../assets/images/error-icon2.png') no-repeat center center;
  }
}