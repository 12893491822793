.inline {
  display: inline-block;
}

.userName {
  margin-right: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}

.redBold {
  color: #FF4444;
  font-weight: 700;
}