.formLabel {
  position: relative;
  color: rgba(0, 0, 0, 0.85);

  &::before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
}

.inline {
  display: inline-block;
}

.thumbWrap {
  display: block;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .prewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.colorBlock {
  margin-right: 16px;
  display: inline-block;
  vertical-align: top;
  width: 60px;
  height: 60px;
  border: 1px solid #e8e8e8;

  &:last-child {
    margin-right: 0px;
  }

  &.cur {
    border-color: #1890ff;
  }
}

.scrollCon {
  max-height: 450px;
  padding-right: 16px;
  overflow: auto;
}

.editRow {
  display: flex;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid #e8e8e8;

  &:last-child {
    margin-bottom: 0px;
  }

  .uploadArea {
    margin-right: 12px;
    width: 120px;
  }

  .inputArea {
    flex: 1;

    .inputLine {
      display: flex;
      margin-bottom: 10px;

      .inputLabel {
        position: relative;
        width: 70px;
        line-height: 32px;
        text-align: right;
        color: rgba(0, 0, 0, 0.85);

        &:after {
          content: ":";
          position: relative;
          top: -0.5px;
          margin: 0 8px 0 2px;
        }
      }

      .inputContainer {
        flex: 1;
      }
    }
  }
}

.cardItem {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  .cardBody {
    position: relative;
  }

  .pic {
    width: 100%;
    border: none;
  }

  .cardMeta {
    display: flex;
    padding: 8px 8px;

    .metaPull {
      line-height: 32px;
    }

    .metaBody {
      flex: 1;
      margin-left: 8px;
    }
  }
}