.mediaExternal {
  width: 450px;
  margin: 0px auto;
}

.mediaContent {
  display: flex;
  align-items: center;
  height: 300px;
}

.mediaFooter {
  padding: 10px 15px;

  .linkBtn {
    color: #bbbbbb;
    font-size: 12px;

    &:hover {
      color: #1890ff;
    }
  }
}

.uploadTips {
  width: 100%;
  height: 260px;
  line-height: 260px;
  font-size: 24px;
  color: #cccccc;
}

.picBox {
  position: relative;
  width: 570px;
  height: 260px;
  overflow: hidden;
  border: 1px dashed #1890ff;

  .pic {
    display: block;
    width: 100%;
  }

  .deleteWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}