.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }

  .required {
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);

    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.bodyContainer {
  display: flex;

  .serverWrap {
    flex: 1;
    padding-right: 48px;
  }
}

.freightWrap {
  width: 320px;
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0px 8px 0px 2px;
  }
}
