.colcontent {
  display: flex;
  align-items: center;
}

.imgBox {
  width: 48px;
  height: 48px;

  &.orderIcon {
    background-image: url('~@/assets/images/icon_total_ordernum.png');
    background-repeat: no-repeat;
  }

  &.stockIcon {
    background-image: url('~@/assets/images/icon_stock_value.png');
    background-repeat: no-repeat;
  }
}

.statisticBox {
  flex: 1;
  margin-left: 16px;
}

.background {
  padding: 15px;
  border-radius: 2px;
  background-color: rgba(24, 144, 255, 0.06);
}

.message {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #1890FF;
}

.description {
  display: block;
  line-height: 32px;
}