
.goodsBaseItem {
  display: flex;
}

.imgBox {
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 80px;
  height: 80px;

  .pic {
    display: block;
    width: 80px;
    background-size: 100% 100%;
  }
}

.brand {
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 2px;
  border-radius: 2px;
  border: solid 1px #0169C6;
  font-size: 12px;
  color: #0169C6;
  line-height: normal;
}

.name {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;
}

.props {
  margin-bottom: 0px;
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}