.verifyImgPanel {
  position: relative;
  margin: 0px;
  width: 330px;
  height: 155px;
  border-left: 1px solid #e4e7eb;
  border-right: 1px solid #e4e7eb;
  box-sizing: content-box;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .verifyRefresh {
    display: inline-block;
    padding: 5px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 20;
    text-align: center;
    cursor: pointer;
  }

  .checkTips {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -32px;
    padding: 0px 10px;
    height: 32px;
    line-height: 32px;
    transition: all 0.3s ease-in;

    &.successBg {
      background-color: rgba(82, 196, 26, 0.45);
    }

    &.errorBg {
      background-color: rgba(245, 34, 45, 0.45);
    }

    &.showTip {
      bottom: 0px;
    }

    .tipText {
      font-size: 14px;
      color: #ffffff;
    }
  }
}

.verifyBarArea {
  position: relative;
  width: 330px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
  box-sizing: content-box;

  .verifyMoveBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 40px;
    background-color: #ffffff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background-color 0.2s linear;
    box-sizing: content-box;

    &:hover {
      background-color: #00A5FF;
      color: #ffffff;
    }

    .verifySubBlock {
      position: absolute;
      text-align: center;
      z-index: 30;
    }

  }

  .verifyLeftBar {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 38px;
    background-color: #E6F6FF;
    cursor: pointer;
    border: 1px solid #00A5FF;
    box-sizing: content-box;
  }

  .verifyMsg {
    font-size: 16px;
    color: #45494c;
    z-index: 30;
  }

}