:global {

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td {
    padding: 8px;
    font-size: 12px;
  }

  .ant-table-thead>tr>th {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }

  .ant-table-selection-column {
    width: 32px;
  }

  .ant-modal-confirm-warning {
    .ant-modal-close {
      display: block;
    }

    .ant-modal-confirm-btns {
      display: none;
    }
  }
}

.leftSider {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 2px 0px 8px 0px rgba(29, 35, 41, 0.05);
}

.singlePage {
  padding: 15px 0px 64px 15px;
}

.noticeWrap {
  padding: 24px;

  h4 {
    font-size: 18px;
    color: #333333;
  }

  p {
    text-indent: 2em;
    font-size: 14px;
    color: #333333;
    margin-bottom: 12px;
  }
}

.noticeIcon {
  display: inline-block;
  width: 24px;
  height: 24px;

  img {
    display: block;
    width: 24px;
  }
}

.noticeDescription {
  cursor: pointer;
}