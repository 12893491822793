.noticeWrap {
  font-size: 14px;
  color: #333333;

  .title {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: normal;
  }

  .subInfo {
    margin-bottom: 20px;
    color: #999999;
    text-align: center;
    line-height: normal;
  }
}