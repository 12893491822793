.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.uploadLand {
  :global {
    .ant-upload.ant-upload-drag {
      background-color: #ffffff;
    }
  }
}

.title {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;

  &.hidden {
    visibility: hidden;
  }
}

.draggerContent {
  display: flex;
  align-items: center;
  height: 268px;

  p {
    font-size: 14px;
    color: #333333;
  }

  .tipText {
    color: #F5222D;
  }

  .imgBox {
    margin-bottom: 16px;

    .pic {
      display: block;
      margin: 0px auto;
      width: 135px;
    }
  }
}

.operateBtns {
  text-align: right;

  .operateBtn {
    padding: 0px;
    margin-left: 12px;
    width: 130px;
    text-align: center;

    &:first-child {
      margin-left: 0px;
    }
  }
}