.progressWrap {
  position: relative;
  width: 320px;
  height: 320px;

  .progressAntd {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 320px;
    transform: translateY(-50%);
    text-align: center;
  }

  .progressTitle {
    position: absolute;
    left: 0px;
    top: 32%;
    width: 320px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
  }

  .progressText {
    position: absolute;
    top: 80px;
    left: 70px;
    padding-top: 30px;
    width: 180px;
    text-align: center;

    .progressLabel {
      margin-bottom: 10px;
    }

    .progressValue {
      font-size: 22px;
      color: #000000;
    }
  }
}

.matchInfo {
  flex: 1;

  .num {
    font-size: 46px;

    &.redColor {
      color: #f5222d;
    }
  }
}