.addressRow {
  padding: 8px 15px;
  margin-bottom: 16px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
}

.extraRow {
  text-align: right;

  .extraItem {
    margin-right: 15px;

    &:hover {
      color: #FF4444;
    }
  }
}

.defaultLabel {
  margin: 0 0 0 12px;
  font-size: 12px;
  background-color: #ffaa45;
  padding: 2px;
  color: #fff;
  font-weight: 400
}