.tipCol {
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;

  &:first-child {
    margin-left: 0px;
  }
}

.tipLabel {
  position: relative;

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 6px 0 2px;
  }
}

.inline {
  display: inline-block;
}

.colorBlock {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  width: 30px;
  height: 14px;

  &.warning {
    background-color: rgba(250, 173, 20, 0.6);
  }
}

.hpriceCol {
  display: flex;
  width: 710px;

  .headPrice {
    flex: 1;
  }

  .headQuantity {
    width: 100px;
    text-align: center;
  }
}

.brand {
  display: inline-block;
  margin-bottom: 5px;
  padding: 1px 2px;
  border-radius: 2px;
  border: solid 1px #0169C6;
  font-size: 12px;
  color: #0169C6;
  line-height: normal;
}

.name {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: normal;
}

.prodProps {
  margin-bottom: 0px;
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .propsLabel {
    position: relative;

    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 6px 0 2px;
    }
  }
}

.priceRow {
  display: flex;
  margin-top: 5px;

  &:first-child {
    margin-top: 0px;
  }
}

.priceCol {
  flex: 1;
}

.quantityCol {
  width: 100px;
  height: 24px;
  text-align: center;
}

.inlineCol {
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.inlineFutures {
    width: 160px;
  }

  .alignCenter {
    display: flex;
    align-items: center;
    height: 24px;
  }
}

.tagKey {
  display: inline-block;
  vertical-align: top;
  padding: 0px 2px;
  font-size: 12px;
  color: #0169C6;
  border: 1px solid #0169C6;
  border-radius: 2px;
  line-height: normal;
  box-sizing: content-box;

  &.futuresTag {
    color: #F03A58;
    border-color: #F03A58;
  }
}

.tagValue {
  margin-left: 5px;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #999999;
  box-sizing: border-box;
}

.specialTag {
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border: 1px solid #FF4444;
  color: #FF4444;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
}



.prodBoximg {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  overflow: hidden;

  .prodBoximgs {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
}