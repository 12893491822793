.thumbnailWrap {
  margin-bottom: 6px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
}

.thumbnailInfo {
  position: relative;
  padding: 0px;
  height: 100%;
  overflow: hidden;
  transition: background-color 0.3s;

  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all .3s;
    content: ' ';
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .previewActions {
      opacity: 1;
    }
  }
}

.thumbnailPic {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
  border: none;
}

.previewActions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}