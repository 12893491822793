.loadingWrap {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 100px;
  text-align: center;
  background-color: rgba(230, 247, 255, 0.45);
  z-index: 1100;
}