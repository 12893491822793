.tabBarLand {
  margin-bottom: 15px;
  padding-top: 15px;
  background-color: #ffffff;
}

.tabBar {
  :global {
    .ant-tabs-bar {
      margin-bottom: 0px;
    }
  }
}