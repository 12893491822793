.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: auto;
    }
  }
}

.formitemBtn {
  display: inline-block;
  padding: 0px 8px;
  height: 40px;
  line-height: 40px;
}

.row {
  display: flex;
  height: 40px;
  line-height: 40px;

  .rowInner {
    flex: auto;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.formitemLabel {
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  &::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

