.invoiceProduct {
  :global {
    .ant-table-footer {
      padding: 8px;
    }
  }

  .label {
    position: relative;

    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
}