.standardFormRow {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.standardFormRowLabel {
  flex: 0 0 auto;
  margin-right: 8px;
  color: rgba(0,0,0, 0.85);
  line-height: 32px;
}

.standardFormRowContent {
  flex: 1 1;
  line-height: 30px;
}
