.filterLand {
  position: relative;
  margin-bottom: 15px;
  height: 56px;
  overflow: hidden;
}

.collapseButton {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.collapseOpen {
  height: auto;
}