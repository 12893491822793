.cardItem {
  padding: 12px;
  border: 1px solid #d9d9d9;
}

.imgBox {
  .pic {
    display: block;
    width: 100%;
  }
}

.cardMeta {
  display: flex;
  margin-bottom: 12px;

  .status {
    flex: 1;
    line-height: 32px;
  }

  .operate {
    margin-left: 12px;
  }
}