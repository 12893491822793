.stepTitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.isRequired {
  position: relative;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.progress {
  background-color: #faad14;
}