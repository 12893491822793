.name {
  padding: 0px 11px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.colTitle {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  padding-right: 10px;
  height: 32px;
  line-height: 32px;

  .delete {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    font-size: 12px;
  }

  .titelLable {
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
  }

  .titleControl {
    margin-left: 10px;
    flex: 1;
  }
}

.listWrapper {
  padding: 0px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.listItem {
  padding: 0px 5px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.colPopover {
  padding: 12px 16px;

  .popoverMessage {
    padding: 4px 0 12px;
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
  }

  .popoverButtons {
    margin-bottom: 4px;
    text-align: right;
  }
}