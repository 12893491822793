.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.required {
  position: relative;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #F5222D;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}