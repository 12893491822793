.announceTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.announceSub {
  margin-bottom: 20px;
  font-size: 12px;
  color: #999999;
  text-align: center;
}

.announceContent {
  font-size: 14px;
  color: #333333;
  text-indent: 2em;
}

.operateLand {
  margin-bottom: 15px;
  display: flex;

  .operateCol {
    flex: 1;
  }

  .actionCol {
    margin-left: 15px;

    .colorBlock {
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 14px;
    }
  }
}