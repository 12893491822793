.freightForm {
  :global {
    .ant-form-item {
      display: flex;
    }
  }
}

.recommendRow {
  padding-top: 6px;
  padding-bottom: 6px;
}

.none {
  display: none;
}

.icon {
  transform: rotate(90deg);
}

.up {
  transform: rotate(-90deg);
}