.inline {
  display: inline-block;
}

.thumbWrap {
  display: inline-block;
  margin: 0px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }

  .prewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    transform: all 0.3s;
    color: #fff;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.detailRow {
  margin-bottom: 5px;

  .detailLabel {
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 8px;
    width: 90px;
    text-align: right;
  }
}