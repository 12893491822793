.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.flex {
  display: flex;
}

.topBar {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;
}

.searchLand {
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;

  &:hover {
    border-color: #008dff;
  }
}

.cateArea {
  margin-right: 16px;
  width: 100px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px solid #e8e8e8;
}

.bannerArea {
  flex: 1;
  height: 150px;
  text-align: center;
  line-height: 150px;
  border: 1px solid #e8e8e8;
  font-size: 16px;
  font-weight: 700;

  &:hover {
    border-color: #008dff;
  }
}

.cardArea {
  margin-left: 16px;
  width: 80px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px solid #e8e8e8;
}

.leftLand {
  flex: 1;
}

.rightLand {
  width: 150px;
  border: 1px solid #e8e8e8;

  .text {
    display: flex;
    height: 300px;
    align-items: center;
    justify-content: center;
  }
}

.special,
.advert {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;

  &:hover {
    border-color: #008dff;
  }
}

.hotsaleArea {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;
}

.specialLand {
  flex: 1;
}

.operateLand {
  margin-left: 16px;
  width: 80px;
}

.btns {
  display: flex;
  align-items: center;
  height: 60px;
}

.fixfooterLand {
  position: fixed;
  left: 200px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(119, 107, 107, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}