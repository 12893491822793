.resultContainer {
  padding-top: 24px;
  text-align: center;
}

.resulticonRow {
  text-align: center;
  margin-bottom: 24px;
}

.resultTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.resultSubtitle {
  margin-bottom: 24px;
  font-size: 16px;
}

.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}