.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.pictureWrap {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  zoom: 1;

  &:first-child {
    margin-left: 0px;
  }
}