.uptemPop {
              .scrollCon {
                height: 350px;
                overflow: scroll;
              }
            }
            
            .inline {
              display: inline-block;
              vertical-align: top;
            }
            
            .thumbWrap {
              display: inline-block;
              margin: 0px 8px 0px 0px;
              vertical-align: top;
              padding: 8px;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              width: 104px;
              height: 104px;
              overflow: hidden;
            
              &.mainIdent {
                position: relative;
                border-color: #1890ff;
              }
            
              .thumbInfo {
                position: relative;
                height: 100%;
                overflow: hidden;
                transition: background-color 0.3s;
            
                img {
                  position: static;
                  width: 100%;
                  height: 100%;
                }
              }
            
              .prewModal {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background-color: rgba(0, 0, 0, 0.5);
                transform: all 0.3s;
                color: #fff;
                opacity: 0;
            
                &:hover {
                  opacity: 1;
                }
              }
            }
            
            .mainTag {
              width: 104px;
              text-align: center;
              font-size: 12px;
              color: #1890ff;
              line-height: normal;
            }
            
            .createUpload {
              position: relative;
              display: inline-block;
              margin: 0px 8px 8px 0px;
              padding: 8px;
              border: 1px dashed #d9d9d9;
              border-radius: 4px;
              width: 104px;
              height: 104px;
              overflow: hidden;
              text-align: center;
              line-height: 98px;
            
              &:hover {
                border-color: #1890ff;
              }
            }
            
            .specsScroll {
              padding-bottom: 20px;
              padding-right: 15px;
              height: 400px;
              overflow-y: scroll;
            }
            
            .specsFooter {
              position: relative;
              padding: 10px 16px;
              text-align: right;
              background: transparent;
              border-top: 1px solid #e8e8e8;
              border-radius: 0 0 4px 4px;
            }
            
            .uploadedList {
              width: 350px;
            }
            
            .uploadedpdfItem {
              height: 100%;
              padding: 4px 5px;
            
              &:hover {
                background-color: #e6f7ff;
              }
            
              .pdfContainer {
                display: flex;
            
                .pdfIcon {
                  width: 24px;
                  color: #666666;
                  font-size: 16px;
                }
            
                .pdfLink {
                  flex: 1;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
            
                .pdfOperate {
                  text-align: right;
                  width: 24px;
                  font-size: 16px;
                }
              }
            }

            
$img:'./../../../assets/images/PDF.png';
.pdfdiv{
              width: 114px;
              height: 114px;
              line-height: 114px;
              text-align: center;
              border: 1px dashed #bbb8b8;
              background: #FAFAFA;
              margin: 0px 8px 0px 0px;
              
               span{
                  margin: 0px 5px;cursor: pointer;
             }
          }
  .pdfbaimg{
    
    padding-top: 1px;
    width: 90%;
    height: 99%;
    margin: 0 auto;
    background-image: url($img);
    background-size:cover ;
  }


          .pdfup{
                   width: 104px;
                   height: 104px;
                   line-height: 104px;
                   margin-left:10px      
                   
          }
          .previewActions {
             position: absolute;
             top: 50%;
             left: 50%;
             z-index: 10;
             white-space: nowrap;
             -webkit-transform: translate(-50%, -50%);
             -ms-transform: translate(-50%, -50%);
             transform: translate(-50%, -50%);
             opacity: 0;
             -webkit-transition: all 0.3s;
             transition: all 0.3s;
           }