.setwrap {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.brickItem {
  border-bottom: 1px dashed #e8e8e8;
}

.brickContainer {
  padding: 5px;

  &.errorBorder {
    border: 1px solid #F5222D;
  }
}


.otherLand {
  .otherLabel {
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;

    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0px 8px 0 2px;
    }
  }

  .otherContainer {
    display: inline-block;
    vertical-align: top;
  }
}

.footerLand {
  position: fixed;
  left: 200px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(119, 107, 107, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}