.popover {
  position: absolute;
  left: 0px;
  top: 24px;
  width: 200px;
  z-index: 200;

  .overInner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .innerTitle {
    width: 100%;
    height: 30px;
    text-align: right;
    border-bottom: 1px solid #e8e8e8;

    .closeBtn {
      padding-right: 10px;
      display: inline-block;
      line-height: 30px;
      font-size: 12px;

      &:hover {
        color: #F5222D;
      }
    }
  }

  .innerContent {
    padding: 10px 15px;
    max-height: 200px;
    overflow: auto;
    font-size: 12px;
  }
}