.formitemRow {
  :global {
    .ant-form-item {
      display: flex;
    }

    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.uploadFormitemRow {
  :global {
    .ant-form-item-control {
      line-height: normal;
    }

    .ant-upload.ant-upload-select-picture-card {
      margin-bottom: 4px;
    }
  }
}

.required {
  position: relative;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}