.stockContent {
  width: 340px;
}

.specialTag {
  margin-left: 4px;
  display: flex;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border: 1px solid #FF4444;
  color: #FF4444;
  border-radius: 10px;
  justify-content: center;
  align-items: center;

}

.compactCol {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;

  :global {
    .ant-select-selection {
      border-right-width: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .ant-input {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.label {
  position: relative;

  &::after {
    content: ':';
    position: relative;
    top: -.5px;
    margin: 0 6px 0 2px;
  }
}

.formmat {
  display: flex;
  align-items: center;
  min-height: 45px;
}