.affixMenuWrap {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  z-index: 999;
}

.menuItem {
  padding: 8px 10px;
  background-color: #646577;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}