.flex {
  display: flex;
}

.solidLine {
  border: 1px solid #e8e8e8;
}

.floorTpl1 {
  padding: 10px;

  .pullLeft {
    width: 160px;
    margin-right: 16px;

    .imgWrap {
      width: 160px;

      &.seat {
        height: 160px;
        border: 1px dashed #e8e8e8;
        text-align: center;
        line-height: 160px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .mediaBody {
    flex: 1;
    display: flex;
    align-items: center;

    &.seat {
      padding: 10px;
      height: 160px;
      border: 1px dashed #e8e8e8;
    }
  }
}

.floorTpl2 {
  padding: 10px;

  .imgWrap {
    display: block;

    &.seat {
      width: 100%;
      height: 100px;
      border: 1px dashed #e8e8e8;
      text-align: center;
      line-height: 100px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

}

.floorTpl3 {
  padding: 10px;

  .floorTitle {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
  }

  .pullLeft {
    width: 160px;
    margin-right: 16px;

    .imgWrap {
      width: 160px;

      &.seat {
        height: 160px;
        border: 1px dashed #e8e8e8;
        text-align: center;
        line-height: 160px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .mediaBody {
    flex: 1;
    display: flex;
    align-items: center;

    &.seat {
      padding: 10px;
      height: 160px;
      border: 1px dashed #e8e8e8;
    }
  }
}

.floorTpl4 {
  padding: 10px;

  .floorTitle {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
  }

  .pullRight {
    width: 160px;
    margin-left: 16px;

    .imgWrap {
      width: 160px;

      &.seat {
        height: 160px;
        border: 1px dashed #e8e8e8;
        text-align: center;
        line-height: 160px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .mediaBody {
    flex: 1;
    display: flex;
    align-items: center;

    &.seat {
      padding: 10px;
      height: 160px;
      border: 1px dashed #e8e8e8;
    }
  }
}

.floorTpl5 {
  padding: 10px;

  .floorTitle {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
  }

  .pullLeft {
    width: 160px;
    margin-right: 16px;
    border: 1px solid #e8e8e8;
    text-align: center;

    .imgWrap {
      width: 160px;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .mediaBody {
    flex: 1;
    display: flex;
    align-items: center;

    .brickItem {
      padding: 6px;
      margin-bottom: 10px;
      border: 1px solid #e8e8e8;
    }

    .figure {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6px auto;
      width: 100px;
      height: 100px;

      img {
        display: block;
        border: 0px;
        width: 100%;
      }
    }

    .name,
    .desc {
      text-align: center;
      font-size: 12px;
      line-height: normal;
      height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}