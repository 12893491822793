ul {
  margin: 0px;
  padding: 0px;
}

ul li {
  list-style: none;
}

p {
  margin: 0px;
}

.pageContainer {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
}

.loadingWrapper {
  display: none;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 100px;
  text-align: center;
  background-color: rgba(230, 247, 255, 0.45);
  z-index: 1999;
}

.visible {
  visibility: hidden;
}

.greenColor {
  color: #52C41A;
}

.redColor {
  color: #F5222D;
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.form-label {
  position: relative;
  line-height: 32px;
}

.form-label:after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.warningBackground {
  background-color: #FF9C00;
}

.light-row {
  background-color: rgba(24, 144, 255, 0.1);
}

.darkBackground {
  background-color: #D4DCE4;
}

.greyBackground {
  background-color: #D9D9D9;
}

.disableBackground {
  background-color: rgba(51, 51, 51, 0.05);
}

.toolbar {
  margin-bottom: 0px;
}

.editorCon {
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  border: 1px solid #f1f1f1;
  border-top: none;
  height: 400px;
  overflow-y: scroll;
}

.inline {
  display: inline-block;
}

.required {
  position: relative;
}

.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #F5222D;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.rowBrandName {
  padding: 0px 2px;
  border: 1px solid #108fe9;
  border-radius: 4px;
  margin-right: 5px;
  font-size: 12px;
}

.coloneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.col100 {
  max-width: 100px;
}

.col130 {
  max-width: 130px;
}

.col150 {
  max-width: 150px;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  visibility: hidden;
  height: 0px;
  width: 0px;
  font-size: 0px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mb10 {
  margin-bottom: 10px;
}